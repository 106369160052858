import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import parse from 'html-react-parser';

const Text = ({ heading, text, columns }) => (
  <Row key={heading}>
    <Col>
      {heading && <h2>{heading}</h2>}
      {text && <p className={columns ? 'long' : ''}>{parse(text)}</p>}
    </Col>
  </Row>
);

Text.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  columns: PropTypes.bool,
};

Text.defaultProps = {
  heading: '',
  text: '',
  columns: false,
};

export default Text;
